import {createRouter,createWebHashHistory} from "vue-router"
import Cookies from 'js-cookie'
import {useStore} from "vuex";
import {myStore} from '@/store/index'
import {inject} from "vue";

const routes =[
    {
        path: '/',
        redirect: '/visitor'
    },
    {
        path: '/resume',
        component:()=>import("@/components/utils/PDF")
    },
    {
        path:'/admin/login',
        name:'login',
        component:()=>import("@/components/admin/login/LoginHome")
    },
    {
        path: '/admin',
        component:()=>import("@/components/admin/AdminHome"),
        meta: {
            needLogin: true //需要加校检判断的路由
        },
        redirect: '/admin/essay',
        children:[
            {
                path:'/admin/administration',
                component:()=>import("@/components/admin/administration/AdministrationHome")
            },
            {
                path: '/admin/essay',
                component:()=>import("@/components/admin/essay/EssayHome")
            },
            {
                path: '/admin/essay/add/:title*',
                component:()=>import("@/components/admin/essay/AddEssay")
            },
            {
                path: '/admin/essay/add/success/:resMessage*',
                component:()=>import("@/components/admin/essay/AddEssaySuccess")
            },
            {
                path: '/admin/essay/update/:essayId',
                component:()=>import("@/components/admin/essay/UpdateEssay")
            },
            {
                path: '/admin/essay/update/success/:resMessage*',
                component:()=>import("@/components/admin/essay/UpdateEssaySuccess")
            },
            {
                path: '/admin/essay/preview/:essayId',
                component:()=>import("@/components/admin/essay/PreviewEssay")
            },
            {
                path: '/admin/draft',
                component:()=>import("@/components/admin/draft/DraftHome")
            },
            {
                path: '/admin/category',
                component:()=>import("@/components/admin/category/CategoryHome")
            }
        ]
    },
    {
        path: "/visitor",
        component:()=>import("@/components/visitor/home/VisitorHome"),
        redirect: '/visitor/getList',
        children:[
            {
                path: "/visitor/getList",
                component:()=>import("@/components/visitor/home/RightHome")
            },
            {
                path: "/visitor/timeline/:date*",
                component:()=>import("@/components/visitor/timeline/TimelineHome")
            },
            {
                path: "/visitor/getList/:type*/by/:category*",
                component:()=>import("@/components/visitor/essayList/EssayListHome")
            },
            {
                path: "/visitor/tags",
                component:()=>import("@/components/visitor/tags/TagsHome")
            },
            {
                path: "/visitor/category",
                component:()=>import("@/components/visitor/category/CategoryHome")
            },
            {
                path: "/visitor/preview/:essayId",
                name:"preview",
                component:()=>import("@/components/visitor/essay/EssayHome")
            }
        ]
    }
]

// 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
// 暂时保持简单
const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    // base:'./',
    routes, // `routes: routes` 的缩写
})

const store=myStore()
router.beforeEach((to, from,next) => {

    if (to.meta.needLogin){
        if (Cookies.get("blog-login-in-free")!==undefined
            &&Cookies.get("blog-login-in-free")==="session"
            ||Cookies.get("blog-login-in-free")==="local")
            next()
        else
            next({
                name:'login'
            })
    }else{

        if (to.name==="preview"){
            store.commit("setDirectory",true);

        }else
            store.commit("setDirectory",false);

        console.log("router  :",store.getters.getDirectory)
        next()
    }

})
// inject('reload2')
router.afterEach((to, from) => {
    if(from.name==="preview"){
        // location.reload()
    }
})

export default router;
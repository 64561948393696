import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { nextTick, onBeforeMount, provide, ref } from "vue";
import { useStore } from "vuex";
export default {
  __name: 'App',

  setup(__props) {
    const store = useStore();
    onBeforeMount(() => {
      // 在页面加载时读取sessionStorage里的状态信息
      if (sessionStorage.getItem("blog-store")) {
        store.replaceState(Object.assign({}, store.state, JSON.parse(sessionStorage.getItem("blog-store"))));
        sessionStorage.removeItem("blog-store");
      } //在页面刷新时将vuex里的信息保存到sessionStorage里


      window.addEventListener("beforeunload", () => {
        sessionStorage.setItem("blog-store", JSON.stringify(store.state));
      });
    });
    const isRouterAlive = ref(true);

    const reload = () => {
      setTimeout(() => {
        isRouterAlive.value = false;
        nextTick(() => {
          isRouterAlive.value = true;
        });
      }, 1000);
    };

    const reload2 = () => {
      setTimeout(() => {
        isRouterAlive.value = false;
        nextTick(() => {
          isRouterAlive.value = true;
        });
      }, 300);
    };

    provide("reload", reload);
    provide("reload2", reload2); // export default {
    //   created() {
    //     //在页面加载时读取sessionStorage里的状态信息
    //     if (sessionStorage.getItem("blog-store")) {
    //       this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem("blog-store"))))
    //       sessionStorage.removeItem("blog-store");
    //     }
    //
    //     //在页面刷新时将vuex里的信息保存到sessionStorage里
    //     window.addEventListener("beforeunload", () => {
    //       sessionStorage.setItem("blog-store", JSON.stringify(this.$store.state))
    //     })
    //   }
    // }

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return isRouterAlive.value ? (_openBlock(), _createBlock(_component_router_view, {
        key: 0
      })) : _createCommentVNode("", true);
    };
  }

};
import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
import store from "@/store";
import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import createLineNumbertPlugin from '@kangc/v-md-editor/lib/plugins/line-number/index';



VMdEditor.use(githubTheme).use(createLineNumbertPlugin());


createApp(App)
    .use(router)
    .use(store)
    .use(VMdEditor)
    .mount('#app')

import Vuex from "vuex"

let currentIndex
const store = new Vuex.Store({

  state: {
    currentIndex:2,
    searchSet:[],
    selectedEssaySet:[],
    isReturn: {
      isRe:false,
      rePath:''
    },
    Loading:false,
    avatar:'',
    signature:'',
    resume:'',
    allCount:{
      essayCount:'',
      categoryCount:'',
      labelCount:''
    },
    essayList:[],
    categoryList:[],
    labelSet:[],
    dateList:[],
    directory:false,
    directoryContent:[],
    previewRef:'',
    currentEssayId:'',
    screenWidth:1025,
    overflowed:true
  },

  mutations: {
    setCurrentIndex(state, currentIndex) {
      state.currentIndex = currentIndex
    },
    setSearchSet(state,searchSet){
      state.searchSet=searchSet
    },
    setSelectedEssaySet(state,selectedEssaySet){
      state.selectedEssaySet=selectedEssaySet
    },
    setIsReturn(state,isReturn){
      state.isReturn=isReturn
    },
    setAvatar(state,avatar){
      state.avatar=avatar
    },
    setSignature(state,signature){
      state.signature=signature
    },
    setResume(state,resume){
      state.resume=resume
    },
    setAllCount(state,allCount){
      state.allCount=allCount
    },
    setEssayList(state,essayList){
      state.essayList=essayList
    },
    setCategoryList(state,categoryList){
      state.categoryList=categoryList
    },
    setLabelSet(state,labelSet){
      state.labelSet=labelSet
    },
    setDateList(state,dateList){
      state.dateList=dateList
    },
    setDirectory(state,directory){
      state.directory=directory
    },
    setDirectoryContent(state,directoryContent){
      state.directoryContent=directoryContent
    },
    setPreviewRef(state,previewRef){
      state.previewRef=previewRef
    },
    setCurrentEssayId(state,currentEssayId){
      state.currentEssayId=currentEssayId
    },
    setScreenWidth(state,screenWidth){
      state.screenWidth=screenWidth
    },
    setOverflowed(state,overflowed){
      state.overflowed=overflowed
    }
  },

  getters: {
    getCurrentIndex(state) {
      return state.currentIndex
    },
    getSearchSet(state){
      return state.searchSet
    },
    getSelectedEssaySet(state){
      return state.selectedEssaySet
    },
    getIsReturn(state){
      return state.isReturn
    },
    getAvatar(state){
      return state.avatar
    },
    getSignature(state){
      return state.signature
    },
    getResume(state){
      return state.resume
    },
    getAllCount(state){
      return state.allCount
    },
    getEssayList(state){
      return state.essayList
    },
    getCategoryList(state){
      return state.categoryList
    },
    getLabelSet(state){
      return state.labelSet
    },
    getDateList(state){
      return state.dateList
    },
    getDirectory(state){
      return state.directory
    },
    getDirectoryContent(state){
      return state.directoryContent
    },
    getPreviewRef(state){
      return state.previewRef
    },
    getCurrentEssayId(state){
      return state.currentEssayId
    },
    getScreenWidth(state){
      return state.screenWidth
    },
    getOverflowed(state){
      return state.overflowed
    }
  }

}
)
export function myStore () {
  return store;
}
export default store